<template>
    <div class="layout">
        <nav-bar></nav-bar>
        <sile-bar></sile-bar>
    </div>
</template>

<script>
import NavBar from './navbar'
import SileBar from './sidebar'

export default {
    components: {
        NavBar,
        SileBar
    }
}
</script>